import React, { useCallback, useState } from "react";
import { editorDoc } from "../../firebase";

import BootstrapSwitchButton from "bootstrap-switch-button-react";

export default function AutoRun({ editorId, showConsole }) {
  const [consoleBtn, setAutoRun] = useState(showConsole);
  const updateAutoSave = useCallback(
    (val) => {
      setAutoRun(val);
      editorDoc.doc(editorId).update({ showConsole: val });
    },
    [editorId]
  );

  return (
    <div style={{ marginRight: 10, display: "inline-block" }}>
      <BootstrapSwitchButton
        onstyle="primary"
        offstyle="light"
        width={150}
        height={25}
        checked={consoleBtn}
        onlabel="Consoles ON"
        offlabel="Consoles OFF"
        onChange={updateAutoSave}
      />
    </div>
  );
}
