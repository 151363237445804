import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCBnb8mwRRo_fCz6xGR9iup6pT4vRmsoZA",
  authDomain: "jscodeeditor.firebaseapp.com",
  projectId: "jscodeeditor",
  storageBucket: "jscodeeditor.appspot.com",
  messagingSenderId: "604162735240",
  appId: "1:604162735240:web:a5aa61d806d3a99b70f33d",
  measurementId: "G-L8FX2DX9T6"
};

const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = app.auth();
export const db = firebase.firestore();

export const editorDoc = db.collection("editor");

export const storage = getStorage(app);
export default app;
