import React, { useRef } from "react";
import Editor from "@monaco-editor/react";


export default function VSEditor({initialValue,localStoreKey, defaultLanguage, onChange}) {

  const editorRef = useRef(null);

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
    const value = window.localStorage.getItem(localStoreKey); //TODO later use
    editorRef.current.setValue(value || initialValue.toString());
  };

  return (
    <Editor
      onChange={onChange}
      height="calc(100vh - 200px)"
      defaultLanguage={defaultLanguage || "javascript"}
      // defaultValue={(initialValue || "").toString()}
      theme="vs-dark"
      onMount={handleEditorDidMount}
    />
  );
}
