import React from "react";
import { Button, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import AutoSave from "./AutoRun";
import ConsoleBtn from "./ConsoleBtn";

export default function Header({ autoRun, saveData, onRun, editorId, showConsole }) {
  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Row style={{ width: "calc(100% + 0px)" }}>
          <Col xs={8} style={{display:'flex',  paddingRight:0}}>
            <Navbar.Brand href="/">JS Editor</Navbar.Brand>
            <Col style={{ textAlign: "right", paddingRight:0 }}>
              {/* <AutoSave editorId={editorId} autoRun={autoRun} /> */}
              <ConsoleBtn editorId={editorId} showConsole={showConsole} />
              <Button
                style={{
                  textDecoration: "none !important",
                }}
                onClick={saveData}
              >
                Save
              </Button>
              <Button
                style={{
                  textDecoration: "none !important",
                  marginLeft:20
                }}
                onClick={onRun}
              >
                Run
              </Button>
            </Col>
          </Col>
          <Col xs={4}></Col>
        </Row>
      </Container>
    </Navbar>
  );
}
