import React from "react";
// import Signup from "../pages/Signup";
import { AuthProvider } from "../contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Login from "../pages/Login";
// import ForgotPassword from "../pages/ForgotPassword";
// import PrivateRoutes from "./PrivateRoutes";
import Dashboard from "../pages/Dashboard";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <Route path="/:editorId/:viewOnly" component={Dashboard} />
          <Route path="/:editorId" component={Dashboard} />
          <Route path="/" component={Dashboard} />

          {/* <Route path="/dashboard" component={Dashboard} /> */}
          {/* <Route path="/signup" component={Signup} />
          <Route path="/login" component={Login} />
          <Route path="/forgot-password" component={ForgotPassword} /> */}
          {/* <PrivateRoutes/> */}
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
