import React, { useCallback, useEffect, useMemo, useState } from "react";
import { editorDoc } from "../../firebase";
import { Col, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import ToastMsg from "../../components/ToastMsg";
import Header from "./Header";
import * as config from "./config";
import Editor from "./editor";

export default function VSEditor() {
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(true);
  const [serverLiveData, updateLiveData] = useState(true);
  const [initialValue, setInitialValue] = useState("");
  const [localStoreKey, setLocalStoreKey] = useState(config?.localKey);
  const [toastData, setToastData] = useState({});

  const [editorValue, setEditorValue] = useState("");

  const params = useParams();
  const history = useHistory();

  const showConsole = useMemo(
    () => !!serverLiveData?.showConsole,
    [serverLiveData]
  );
  const autoRun = useMemo(() => !!serverLiveData?.autoRun, [serverLiveData]);

  const getDataAutoUpdate = useCallback(async () => {
    editorDoc.doc(params?.editorId).onSnapshot((docSnapshot) => {
      updateLiveData(docSnapshot.data());
    });
  }, [params.editorId]);

  const getData = useCallback(async () => {
    await editorDoc
      .doc(params?.editorId)
      .get()
      .then((eData) => {
        updateLiveData(eData.data());
        setInitialValue(eData.data()?.data);
        setEditorValue(eData.data()?.data);
      })
      .finally(() => setLoading(false));
  }, [params.editorId]);

  useEffect(() => {
    if (params?.editorId) {
      setLocalStoreKey(params?.editorId);
      getDataAutoUpdate();
      getData();
    } else {
      updateLiveData(config?.defaultData);
      editorDoc
        .add(config?.defaultData)
        .then((docRef) => {
          setLocalStoreKey(docRef.id);
          history.replace(`/${docRef.id}`);
          getDataAutoUpdate();
        })
        .finally(() => setLoading(false));
    }
  }, [getData, getDataAutoUpdate, history, params]);

  const saveData = debounce(() => saveDataOrg(), 10000);
  const runCode = debounce(() => runCodeForce(), 1000);

  const saveDataOrg = async () => {
    const localStoreKeyData = await window.localStorage.getItem(localStoreKey);
    if (params?.editorId && editorValue !== localStoreKeyData) {
      window.localStorage.setItem(localStoreKey, editorValue);
      editorDoc
        .doc(params?.editorId)
        .update({ data: editorValue })
        .then((docRef) => {
          setToastData({
            title: "Data Saved !!",
            type: "success",
            dateTime: new Date(),
          });
        })
        .catch((e) => {});
    }
  };

  // const runCodeOrg = () => {
  //   try {
  //     window.localStorage.setItem(localStoreKey, editorValue); //TODO later use
  //     if (data?.autoRun) {
  //       console.everything = [];
  //       setResult(console.everything);
  //       // eslint-disable-next-line no-eval
  //       eval(editorValue);
  //     }
  //     saveData();
  //   } catch (err) {
  //     console.error("err", err);
  //     setResult([
  //       { type: "❌ error", datetime: new Date(), value: [err.toString()] },
  //       ...result,
  //     ]);
  //   }
  // };

  const runCodeForce = () => {
    try {
      window.localStorage.setItem(localStoreKey, editorValue); //TODO later use
      console.everything = [];
      setResult(console.everything);
      // eslint-disable-next-line no-eval
      eval(editorValue);
      saveDataOrg();
    } catch (err) {
      console.error("err", err);
      setResult([
        { type: "❌ error", datetime: new Date(), value: [err.toString()] },
        ...result,
      ]);
    }
  };

  const onChange = useCallback(
    (val) => {
      setEditorValue(val);
      saveData();
      if (autoRun) {
        runCode();
      }
    },
    [autoRun, runCode, saveData]
  );

  return (
    <>
      <ToastMsg {...toastData} />
      {params?.editorId && !loading ? (
        <Header
          editorId={params?.editorId}
          autoRun={autoRun}
          saveData={saveDataOrg}
          onRun={runCodeForce}
          showConsole={showConsole}
        />
      ) : (
        <></>
      )}
      {showConsole ? (
        <Row>
          <Col xs={8}>
            {!loading && params?.editorId && (
              <Editor
                onChange={onChange}
                initialValue={initialValue}
                localStoreKey={localStoreKey}
              />
            )}
          </Col>
          <Col
            xs={4}
            style={{
              overflow: "scroll",
              maxHeight: "calc(100vh - 56px)",
              padding: 20,
            }}
          >
            {result?.map(({ type, datetime, value }, index) => {
              return (
                <div
                  style={{
                    color: type === "error" ? "red" : "black",
                    paddingBottom: 5,
                    marginBottom: 10,
                    borderBottom: "1px solid #eee",
                  }}
                  key={`${index}`}
                >
                  {type} :{" "}
                  {value?.map((item, i) => {
                    return (
                      <span key={`${index}-${i}`}> {JSON.stringify(item)}</span>
                    );
                  })}
                </div>
              );
            })}
          </Col>
        </Row>
      ) : (
        <>
          {!loading && params?.editorId && (
            <Editor
              onChange={onChange}
              initialValue={initialValue}
              localStoreKey={localStoreKey}
            />
          )}
        </>
      )}
    </>
  );
}
