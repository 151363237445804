import React from "react";
// import { db } from "../firebase";
// import ContentWithNavBar from "../components/ContentWithNavBar";
import Editor from "../components/Editor";
import AdSense from "react-adsense";
import { Col, Container, Row } from "react-bootstrap";

export default function Dashboard() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 80,
        }}
      >
        <amp-ad
          width="100vw"
          height="320"
          type="adsense"
          data-ad-client="ca-pub-4527040931391941"
          data-ad-slot="3374692439"
          data-auto-format="rspv"
          data-full-width=""
        >
          <div overflow=""></div>
        </amp-ad>
      </div>
      <Editor />
      {/* <Row>
        <Col xs={1}>
          <amp-ad
            width="100vw"
            height="320"
            type="adsense"
            data-ad-client="ca-pub-4527040931391941"
            data-ad-slot="4749700892"
            data-auto-format="rspv"
            data-full-width=""
          >
            <div overflow=""></div>
          </amp-ad>
        </Col>
        <Col xs={10}>
          <Editor />
        </Col>
        <Col xs={1}>
          <amp-ad
            width="100vw"
            height="320"
            type="adsense"
            data-ad-client="ca-pub-4527040931391941"
            data-ad-slot="4749700892"
            data-auto-format="rspv"
            data-full-width=""
          >
            <div overflow=""></div>
          </amp-ad>
        </Col>
      </Row> */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: 64,
        }}
      >
        <amp-ad
          width="100vw"
          height="320"
          type="adsense"
          data-ad-client="ca-pub-4527040931391941"
          data-ad-slot="3374692439"
          data-auto-format="rspv"
          data-full-width=""
        >
          <div overflow=""></div>
        </amp-ad>
      </div>
    </>
  );

  // return (
  //   <>
  //     <ContentWithNavBar page="dashboard">
  //         <Editor />
  //     </ContentWithNavBar>
  //   </>
  // );
}
